import React from "react";
import { interestRateLow } from "../utils/constants";
import { getTotal, concatPrice } from "../utils";
import locale from "../utils/locale";

export const IdeaSection = ({ years, ...props }) => {
  return (
    <div className="idea-section">
      <div className="heading-group">
        <h2 className="fs-36 fw-bold">{locale.ideaSectionTitle}</h2>
        <p>{locale.ideaSectionSub}</p>
      </div>
      <div className="idea-card-grid card-grid">
        {locale.ideaSectionData.map(
          ({ color, icon, description, value, duration, content, url }, i) => {
            return (
              <div
                key={i}
                className="idea-card card"
                style={{ borderColor: color }}
              >
                <div className="card-content flex-column">
                  <div>
                    {icon && <div className="icon-wrapper">{icon}</div>}
                    <h3 className="fs-20">{description}</h3>
                  </div>
                  <p className="idea-card__value tc-blue">
                    {concatPrice(
                      getTotal(years, value, duration, interestRateLow)
                    )}
                  </p>
                </div>
                <div className="card-hover-content flex-column">
                  <div>
                    <h4 className="fs-16 mb-1">{description}</h4>
                    {content.map(item => (
                      <p key={item} className="fs-14 mb-05">
                        {item}
                      </p>
                    ))}
                    {icon && <div className="icon-wrapper">{icon}</div>}
                  </div>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ta-right tc-pink"
                    children="More Info"
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
