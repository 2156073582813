import React from 'react';
import { Toggle } from './Toggle';

export const ChartInflationRow = ({ inflationRate, heading, handleChange }) => {
  return (
    <div
      style={{
        paddingTop: '1.5em',
        marginBottom: '-1em',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Toggle handleChange={handleChange} />
      <label
        className="fs-12 fw-bold"
        style={{
          textDecoration: inflationRate ? 'none' : 'line-through',
          marginLeft: '.5em',
          opacity: inflationRate ? '.6' : '.3'
        }}
      >
        {heading}
      </label>
    </div>
  );
};
