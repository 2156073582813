import React from "react";
import { Tooltip } from "@vx/tooltip";
import { formatPrice } from "../utils";

export const Tooltips = ({
  tooltipTop,
  tooltipLeft,
  tooltipData,
  x,
  dataBc,
  minAge,
}) => {
  console.log({ tooltipLeft });
  const leftPosition = tooltipLeft < 240 ? tooltipLeft + 12 : tooltipLeft - 100;
  return (
    <div>
      <Tooltip
        className="tooltip"
        top={tooltipTop - 24}
        left={leftPosition}
        children={formatPrice(tooltipData.value)}
      />
      <Tooltip
        className="tooltip"
        top={tooltipData.top2}
        left={leftPosition}
        children={formatPrice(tooltipData.value2.value)}
      />
      <span
        className="age-indicator"
        style={{
          position: "absolute",
          bottom: "-30px",
          transform: `translateX(${tooltipLeft - 30}px)`,
        }}
        children={`${x(tooltipData) - x(dataBc[0]) + minAge + 1} years old`}
      />
    </div>
  );
};
