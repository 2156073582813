import React from 'react';

export const ChartRow = ({ heading, bestCase, worstCase }) => {
  return (
    <div className="chart-row">
      <div className="chart-row__description">
        <h4 className="fs-14">{heading}</h4>
      </div>
      <div className="chart-row__values">
        <span className="chart-row__value fs-14" children={bestCase} />
        <span className="chart-row__value fs-14" children={worstCase} />
      </div>
    </div>
  );
};
