import React from "react";
import locale from "../utils/locale";

export const EmailSignup = () => {
  return (
    <form
      action="https://ProductsByPractice.us10.list-manage.com/subscribe/post?u=906ce996f382c8419c328cb85&amp;id=de9f0a2ca5"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      rel="noopener noreferrer"
      noValidate
    >
      <div className="input-group">
        <input
          type="email"
          name="EMAIL"
          className="sf-input email"
          id="mce-EMAIL"
          placeholder={locale.footerPlaceholder}
          required
        />
        <button className="sf-btn" type="submit" children="Subscribe" />
      </div>
      <label className="sf-label" htmlFor="subscribe-input">
        {locale.footerLabel}
      </label>
    </form>
  );
};
