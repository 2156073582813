import React from "react";
import { EmailSignup } from "./EmailSignup";

export const CardSignup = () => (
  <div className="card signup-card mb-2">
    <h2 className="mb-1">Get the latest in your inbox</h2>
    <p>
      Subcribe to be the first to know when we publish updates and get the
      latest investment tips.
    </p>
    <EmailSignup />
  </div>
);
