import React from "react";
import Helmet from "react-helmet";
import animate, { delay } from "../utils/animate";
import { random } from "../utils";
import { Footer } from "../components";
import preview from "../images/meta-preview.jpg";
import favicon from "../images/favicon.png";
import icon from "../images/touch-icon.png";
import "normalize.css";
import "react-input-range/lib/css/index.css";
import "./index.css";

const description =
  "Investment Calculator is a beautifully simple calculator to help you calculate the potential value of your retirement investments and visualize their growth. Based on your inputs, we also make suggestions on how to increase your investment savings.";
class TemplateWrapper extends React.Component {
  componentDidMount() {
    delay(30).then(() => {
      this.handleAnimate();
    });
  }

  handleAnimate = () => {
    animate({
      elements: ".animate-bg",
      duration: 800,
      easing: "in-out-cubic",
      opacity: [0, 1],
      transform: ["translateY(-60px)", "translateY(0)"]
    });
    animate({
      elements: ".animate",
      delay: i => i * 200 + 100,
      duration: 1200,
      easing: "in-out-cubic",
      opacity: [0, 1],
      transform: ["translateY(200px)", "translateY(0)"]
    });
    animate({
      elements: ".animate-artifact",
      duration: 25000,
      delay: index => index * 20,
      easing: "linear",
      loop: true,
      direction: "alternate",
      opacity: [random(0.5, 0.75), 0.8],
      transform: [
        `translateX(0px) translateY(0px) rotate(0deg)`,
        `translateX(${random(300, 400)}px) translateY(${random(
          2,
          10
        )}px) rotate(${random(0, 360)}deg)`
      ]
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet
          title="Investment Calculator"
          meta={[
            {
              name: "title",
              content: "Investment Calculator"
            },
            {
              name: "description",
              content: description
            },
            {
              name: "keywords",
              content: "investment, calculator, 401k, retirement, growth"
            },
            {
              property: "og:site_name",
              content: "Investment Calculator"
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              name: "og:title",
              content: "Investment Calculator"
            },
            {
              name: "og:description",
              content: description
            },
            {
              property: "og:image",
              content: `https://investmentcalculator.io${preview}`
            },
            {
              property: "og:site_name",
              content: "Investment Calculator"
            },
            {
              name: "twitter:domain",
              content: "investmentcalculator.io"
            },
            {
              name: "twitter:card",
              content: "summary_large_image"
            },
            {
              name: "twitter:image",
              content: `https://investmentcalculator.io${preview}`
            },

            {
              name: "twitter:url",
              content: "https://investmentcalculator.io"
            },
            {
              name: "twitter:description",
              content: description
            }
          ]}
          link={[
            {
              rel: "shortcut icon",
              type: "image/x-icon",
              href: favicon
            },
            {
              rel: "icon",
              sizes: "192x192",
              href: icon
            }
          ]}
        >
          <html lang="en-US" />
        </Helmet>
        {this.props.children}
        <Footer />
      </React.Fragment>
    );
  }
}

export default TemplateWrapper;
