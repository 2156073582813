import React from "react";
import locale from "../utils/locale";
import NumberFormat from "react-number-format";

export default ({ setValue, selected }) => {
  return (
    <NumberFormat
      type="tel"
      prefix="$"
      allowNegative={false}
      className={`input ${selected ? "selected" : ""}`}
      thousandSeparator={true}
      placeholder={locale.inputPlaceholder}
      onValueChange={({ floatValue }) => setValue(floatValue)}
    />
  );
};
