import React from "react";
import locale from "../utils/locale";

export const TipSection = props => {
  return (
    <div className="tip-section animate">
      <div className="heading-group">
        <h2 className="fs-36 fw-bold">{locale.tipSectionTitle}</h2>
        <p>{locale.tipSectionSub}</p>
      </div>
      <div className="tip-card-grid card-grid">
        {locale.articles.map(({ description, url, color }, i) => {
          return (
            <div
              key={i}
              className="card tip-card"
              style={{ borderColor: color }}
            >
              <h3 className="fs-20">{description}</h3>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="idea-card__value tc-green"
              >
                {locale.readMore}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
