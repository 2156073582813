import React from "react";
import { RangeInput } from "./RangeInput";
import HeroCard from "./HeroCard";
import Button from "./Button";
import Input from "./Input";
import { NavBar } from "./NavBar";
import locale from "../utils/locale";
import { getData, recommendedBalance, recommendedSavings } from "../utils";
import { savingPresets, balancePresets } from "../utils/constants";

export const Hero = ({
  monthlySavings,
  balance,
  years,
  min,
  max,
  value,
  interestRateHigh,
  interestRateLow,
  inflationRate,
  setBalance,
  setValue,
  setInitialBalance,
  handleChange,
  setMonthlySaving,
  setConservativeRate,
  setAgressiveRate,
  adjustForInflation
}) => {
  return (
    <div className="hero-bg animate-bg">
      <NavBar />
      <div className="container ">
        <div className="hero-content grid ">
          <div className="hero-form grid-column animate">
            <h2 className="tc-white form-title mb-1">
              {locale.columnHeading1}
            </h2>
            <div className="form-group">
              <RangeInput value={value} handleChange={handleChange} />
            </div>

            <div className="input-wrapper">
              <div className="form-group">
                <h4 className="tc-green fs-14">{locale.formHeading2}</h4>
                <div className="btn-group">
                  {balancePresets.map((balancePreset, i) => {
                    return (
                      <Button
                        key={i}
                        recommended={balancePreset === recommendedBalance(min)}
                        value={balancePreset}
                        selected={balancePreset === balance}
                        onClick={setInitialBalance}
                      />
                    );
                  })}
                  <Input
                    selected={!balancePresets.includes(balance)}
                    setValue={setBalance}
                  />
                </div>
                <p className="rec-text">{locale.recommendedText} </p>
              </div>

              <div className="form-group">
                <h4 className="tc-green fs-14">{locale.formHeading1}</h4>
                <div className="btn-group">
                  {savingPresets.map((savingPreset, i) => {
                    return (
                      <Button
                        key={i}
                        recommended={savingPreset === recommendedSavings(min)}
                        value={savingPreset}
                        selected={savingPreset === monthlySavings}
                        onClick={setMonthlySaving}
                      />
                    );
                  })}
                  <Input
                    selected={!savingPresets.includes(monthlySavings)}
                    setValue={setValue}
                  />
                </div>
                <p className="rec-text">{locale.recommendedText} </p>
              </div>
            </div>
          </div>
          <div className="grid-column animate">
            <HeroCard
              minAge={min}
              maxAge={max}
              adjustForInflation={adjustForInflation}
              interestRateHigh={interestRateHigh}
              interestRateLow={interestRateLow}
              inflationRate={inflationRate}
              setAgressiveRate={setAgressiveRate}
              setConservativeRate={setConservativeRate}
              dataBc={getData(
                years,
                interestRateHigh,
                monthlySavings,
                balance,
                inflationRate
              )}
              dataWc={getData(
                years,
                interestRateLow,
                monthlySavings,
                balance,
                inflationRate
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
