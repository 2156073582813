import React from "react";
import { clamp } from "../utils";
import {
  inflationRate,
  interestRateHigh as irh,
  interestRateLow as irl
} from "../utils/constants";

export class StateProvider extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      value: { min: 30, max: 65 },
      monthlySavings: 100,
      balance: 0,
      interestRateHigh: irh,
      interestRateLow: irl,
      inflationRate: null
    };
  }

  handleChange = value => {
    this.setState({ value });
  };

  setMonthlySaving = monthlySavings => {
    this.setState({ monthlySavings });
  };

  setInitialBalance = balance => {
    this.setState({ balance });
  };

  setValue = monthlySavings => {
    this.setState({ monthlySavings });
  };

  setBalance = balance => {
    this.setState({ balance });
  };

  setAgressiveRate = value => {
    this.setState({
      interestRateHigh: Boolean(value) ? clamp(value, 1, 40) : 10.3
    });
  };

  setConservativeRate = value => {
    this.setState({
      interestRateLow: Boolean(value) ? clamp(value, 1, 40) : 7.2
    });
  };

  adjustForInflation = bool => {
    this.setState({ inflationRate: bool ? inflationRate : null });
  };

  render() {
    const {
      monthlySavings,
      interestRateHigh,
      interestRateLow,
      inflationRate,
      balance,
      value,
      value: { min, max }
    } = this.state;

    const years = max - min;

    return this.props.render({
      monthlySavings,
      balance,
      years,
      min,
      max,
      value,
      interestRateHigh,
      interestRateLow,
      inflationRate,
      setBalance: this.setBalance,
      setValue: this.setValue,
      setInitialBalance: this.setInitialBalance,
      handleChange: this.handleChange,
      setMonthlySaving: this.setMonthlySaving,
      setConservativeRate: this.setConservativeRate,
      setAgressiveRate: this.setAgressiveRate,
      adjustForInflation: this.adjustForInflation
    });
  }
}
