import React from "react";
import NumberFormat from "react-number-format";
import { formatPrice } from "../utils";
import { artifacts } from "../utils/icons";
import imageSrc from "../images/salary-helper.svg";
import "./SalaryCalculator.css";

export class SalaryCalculator extends React.Component {
  static defaultProps = {
    showHeading: true
  };
  state = {
    salary: 45000
  };

  componentDidMount() {
    if (!this.props.showHeading) {
      this.inputElem.focus();
    }
  }
  render() {
    const salary = this.state.salary ? this.state.salary : 0;
    return (
      <div className="salary-calculator animate">
        {this.props.showHeading && (
          <div className="heading-group">
            <h2 className="fs-36 fw-bold">How much should I be saving?</h2>
            <p>Find out how you are doing at your age based on your salary</p>
          </div>
        )}
        <div className="card">
          <div className="salary-media">
            <div
              style={{
                position: "absolute",
                height: "400px",
                top: "0px"
              }}
            >
              {artifacts}
            </div>
            <img src={imageSrc} alt="illustration of financial instructor" />
          </div>
          <div className="salary-content">
            <h3 className="tc-green mb-1 fs-14">Enter Salary</h3>
            <NumberFormat
              getInputRef={el => (this.inputElem = el)}
              type="tel"
              prefix="$"
              allowNegative={false}
              className="salary-input mb-1"
              thousandSeparator={true}
              placeholder="$45,000"
              onValueChange={({ floatValue: salary }) => {
                this.setState({ salary });
              }}
            />
            <div className="saving-suggestion-wrapper mb-2">
              <div className="saving-suggestion">
                <span>30 Years Old</span>

                <span className="tc-purple">{formatPrice(salary)}</span>
              </div>
              <div className="saving-suggestion">
                <span>40 Years Old</span>
                <span className="tc-purple">{formatPrice(salary * 3)}</span>
              </div>
              <div className="saving-suggestion">
                <span>50 Years Old</span>
                <span className="tc-purple">{formatPrice(salary * 6)}</span>
              </div>
              <div className="saving-suggestion">
                <span>60 Years Old</span>
                <span className="tc-purple">{formatPrice(salary * 8)}</span>
              </div>
            </div>
            <p>
              As a rule of thumb, your goal should be able to save 1x of your
              salary by the time you are 30 by saving 15% of your income or {""}
              <span className="tc-purple fw-bold">
                {formatPrice((salary / 12) * 0.15)} / mo
              </span>
              . This may seem like a stretch if you have student loans or other
              debt to pay off, but you may be surprised how much you are able to
              save if you have your retirement savings automatically taken out
              of your paycheck. Of course, this is still a fairly ambitious
              goal, but if you need ideas on how to save more money check out
              some of the tips below.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
