import React from 'react';
import { concatPrice } from '../utils';

export default ({ value, selected, recommended, onClick }) => {
  const sel = selected ? 'selected' : '';
  const rec = recommended ? 'recommended' : '';
  return (
    <button
      className={`btn ${sel} ${rec}`}
      onClick={() => {
        onClick(value);
      }}
      children={concatPrice(value)}
    />
  );
};
