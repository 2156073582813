import React from 'react';
import { EmailSignup } from './EmailSignup';
import { Logo } from './Logo';
import locale from '../utils/locale';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container inset">
        <div className="footer-content">
          <div>
            <h5 className="tc-green">{locale.footerSub}</h5>
            <h2 className="fs-36 tc-white">{locale.footerHeading}</h2>
            <p className="tc-white">{locale.footerDescription}</p>
          </div>
          <div className="form-wrapper">
            <EmailSignup />
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <Logo />
        <p className="copyright">{locale.copyright}</p>
      </div>
    </footer>
  );
};
