import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import './ChartInputHeading.css';

export class ChartInputHeading extends PureComponent {
  render() {
    const { placeholder, label, setValue, color = '#ff82ad' } = this.props;
    return (
      <div className="heading-input-wrapper">
        <span
          className="heading-input-label fs-12"
          style={{ color, display: 'block' }}
        >
          {label}
        </span>
        <NumberFormat
          className="heading-input tc-pink fs-12"
          style={{ borderColor: color, color }}
          type="tel"
          suffix="%"
          allowNegative={false}
          placeholder={placeholder}
          onValueChange={({ floatValue }) => setValue(floatValue)}
        />
      </div>
    );
  }
}
