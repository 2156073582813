import React from 'react';
import { Link } from "gatsby"
import { Logo } from './Logo';
import './NavBar.css';

export const NavBar = () => {
  return (
    <nav className="nav-bar">
      <Link to="/" style={{ paddingTop: '1em' }}>
        <Logo />
      </Link>

      <Link to="/blog" className="nav-item">
        Blog
      </Link>
    </nav>
  );
};
