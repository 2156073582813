import React from 'react';
import { Line } from '@vx/shape';

export const HoverLine = ({ from, to, tooltipLeft, tooltipTop, bottomTop }) => {
  return (
    <g>
      <Line
        from={from}
        to={to}
        stroke="#2ddcd4"
        strokeWidth={1}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={tooltipLeft}
        cy={tooltipTop}
        r={6}
        fill="#fff"
        fillOpacity={0.8}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={tooltipLeft}
        cy={tooltipTop}
        r={4}
        fill="#FF5A9B"
        fillOpacity={0.8}
        style={{ pointerEvents: 'none' }}
      />

      <circle
        cx={tooltipLeft}
        cy={bottomTop}
        r={6}
        fill="#fff"
        fillOpacity={0.8}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={tooltipLeft}
        cy={bottomTop}
        r={4}
        fill="#6F42BF"
        fillOpacity={0.8}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};
