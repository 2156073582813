import React from "react";
import { withParentSize, ScaleSVG } from "@vx/responsive";
import { GridColumns } from "@vx/grid";
import { Bar, LinePath } from "@vx/shape";
import { scaleLinear } from "@vx/scale";
import { withTooltip } from "@vx/tooltip";
import { Tooltip } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import { extent, max, bisector } from "d3-array";
import { HoverLine } from "./Hoverline";
import { Tooltips } from "./Tooltips";
import { concatPrice } from "../utils";

// accessors
const x = d => d.date;
const y = d => d.value;
const bisectDate = bisector(d => d.date).left;
const bisectValue = bisector(d => d.value).left;

class Chart extends React.Component {
  constructor() {
    super(...arguments);
    this.handleTooltip = this.handleTooltip.bind(this);
  }

  getDataValue(event, data, x, xScale, yScale) {
    const { x: xPoint } = localPoint(this.svg, event);
    const x0 = Math.round(xScale.invert(xPoint));
    const index = bisectDate(data, x0, 1);

    const d0 = data[index - 1];
    const d1 = data[index];
    return x0 - xScale(x(d0)) > xScale(x(d1)) - x0 ? d1 : d0;
  }

  handleTooltip({ event, dataBc, dataWc, x, xScale, yScale }) {
    const { showTooltip } = this.props;
    const d = this.getDataValue(event, dataBc, x, xScale, yScale);
    const d2 = this.getDataValue(event, dataWc, x, xScale, yScale);
    showTooltip({
      tooltipData: {
        ...d,
        left2: xScale(x(d2)),
        top2: yScale(y(d2)),
        value2: d2
      },
      tooltipLeft: xScale(x(d)),
      tooltipTop: yScale(y(d))
    });
  }

  render() {
    const {
      minAge,
      parentWidth = 500,
      height = 220,
      tooltipTop,
      tooltipLeft,
      hideTooltip,
      dataBc,
      dataWc,
      tooltipData
    } = this.props;

    // bounds
    const xMax = parentWidth;
    const yMax = height;

    // Bisectors
    const topMil = bisectValue(dataBc, 1000000, 1);
    const bottomMil = bisectValue(dataWc, 1000000, 1);

    const bcX = dataBc[topMil];
    const wcX = dataWc[bottomMil];
    const lastBc = dataBc[dataBc.length - 1];
    const lastWc = dataWc[dataWc.length - 1];

    const scaleValue = lastWc.value > lastBc.value ? dataWc : dataBc;

    // scales
    const xScale = scaleLinear({
      range: [0, xMax],
      domain: extent(scaleValue, x)
    });

    const yScale = scaleLinear({
      range: [yMax, 0],
      domain: [0, max(scaleValue, y)]
    });

    return (
      <div ref={s => (this.svg = s)} style={{ marginTop: "2em" }}>
        <ScaleSVG width={parentWidth} height={height}>
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#3023AE" stopOpacity={1} />
              <stop offset="100%" stopColor="#C86DD7" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#FF2547" stopOpacity={1} />
              <stop offset="60%" stopColor="#FF78CA" stopOpacity={1} />
              <stop offset="100%" stopColor="#FF879B" stopOpacity={1} />
            </linearGradient>
          </defs>

          <LinePath
            strokeLinecap="round"
            data={dataBc}
            x={d => xScale(x(d))}
            y={d => yScale(y(d))}
            stroke={"url(#gradient2)"}
            strokeWidth={3}
          />

          <LinePath
            strokeLinecap="round"
            data={dataWc}
            x={d => xScale(x(d))}
            y={d => yScale(y(d))}
            stroke={"url(#gradient1)"}
            strokeWidth={3}
          />

          {bcX && (
            <text
              x={xScale(x(bcX)) - 12}
              y={yScale(y(dataBc[topMil])) + 8}
              fill={"white"}
              fontSize={22}
              children={"💰"}
            />
          )}

          {wcX && (
            <text
              x={xScale(x(wcX)) - 12}
              y={yScale(y(dataWc[bottomMil])) + 8}
              fill={"white"}
              fontSize={22}
              children={"💰"}
            />
          )}
          <GridColumns
            scale={xScale}
            numTicks={dataBc.length}
            height={yMax}
            stroke="rgba(5, 5, 5, .05)"
          />
          <Bar
            width={parentWidth}
            height={height}
            fill="transparent"
            onTouchStart={event =>
              this.handleTooltip({
                event,
                dataBc,
                dataWc,
                x,
                xScale,
                yScale
              })
            }
            onTouchMove={event =>
              this.handleTooltip({
                event,
                dataBc,
                dataWc,
                x,
                xScale,
                yScale
              })
            }
            onMouseMove={event =>
              this.handleTooltip({
                event,
                dataBc,
                dataWc,
                x,
                xScale,
                yScale
              })
            }
            onMouseLeave={event => hideTooltip()}
          />

          {tooltipData && (
            <HoverLine
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
              bottomTop={tooltipData.top2}
            />
          )}
        </ScaleSVG>

        <Tooltip
          className="total-tooltip"
          style={{
            backgroundColor: "none",
            color: "#FF82AD",
            padding: "0",
            borderRadius: "3px",
            fontSize: "12px",
            boxShadow: "none"
          }}
          left={xScale(x(lastBc)) + 4}
          top={yScale(y(lastBc))}
          fill={"gradient1)"}
          children={concatPrice(y(lastBc))}
        />

        <Tooltip
          className="total-tooltip"
          style={{
            backgroundColor: "none",
            color: "#B564D2",
            padding: "0",
            borderRadius: "3px",
            fontSize: "12px",
            boxShadow: "none"
          }}
          left={xScale(x(lastWc)) + 4}
          top={yScale(y(lastWc))}
          fill={"gradient1)"}
          children={concatPrice(y(lastWc))}
        />

        {tooltipData && (
          <Tooltips
            tooltipData={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            x={x}
            dataBc={dataBc}
            minAge={minAge}
          />
        )}
      </div>
    );
  }
}

export default withParentSize(withTooltip(Chart));
