import React from 'react';
import InputRange from 'react-input-range';
import './RangeInput.css';

export class RangeInput extends React.Component {
  static defaultProps = {
    minAge: 0,
    maxAge: 100,
    ticks: 100,
    value: { min: 25, max: 65 }
  };

  constructor({ value }) {
    super(...arguments);
    this.state = {
      value
    };
  }

  getHeight = (i, minVal, maxVal) => {
    const { ticks, maxAge } = this.props;
    const ratio = maxAge / ticks;
    const offset = ticks / maxAge;
    const first = minVal === i * ratio || maxVal === i * ratio;
    const second =
      minVal - offset === i * ratio ||
      minVal + offset === i * ratio ||
      maxVal - offset === i * ratio ||
      maxVal + offset === i * ratio;
    return first ? '18px' : second ? '14px' : '10px';
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    const { min, max } = this.state.value;
    const { ticks, minAge, maxAge } = this.props;
    return (
      <div style={{ padding: '3em 0 1em' }}>
        <div
          style={{
            position: 'relative',
            width: '100%'
          }}
        >
          {Array.from({ length: ticks }).map((item, i) => {
            return (
              <span
                key={`tick-mark-${i}`}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: `${i * (100 / ticks)}%`,
                  height: this.getHeight(i, min, max),
                  width: '1px',
                  backgroundColor:
                    i >= min && i <= max
                      ? '#1DB2F7'
                      : 'rgba(255, 255, 255, .4)',
                  transition:
                    'height .3s ease-in-out, background-color .3s ease-in-out'
                }}
              />
            );
          })}
        </div>
        <InputRange
          formatLabel={value =>
            max - min > 20 ? `${value} years old` : `${value} y/o`
          }
          maxValue={maxAge}
          minValue={minAge}
          value={this.state.value}
          onChange={this.handleChange}
          onChangeComplete={value => this.props.handleChange(value)}
        />
      </div>
    );
  }
}
