import React from 'react';
import { IdeaSection } from './IdeaSection';
import { HowSection } from './HowSection';
import { TipSection } from './TipsSection';
import { RetirementCalculator } from './RetirementCalculator';
import { SalaryCalculator } from './SalaryCalculator';
import { artifacts } from '../utils/icons';
// import { Sidebar } from './Sidebar';

export const ContentSection = props => {
  return (
    <section className="content-section" style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '400px',
          top: '0px',
          zIndex: '-1'
        }}
      >
        {artifacts}
      </div>

      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '400px',
          bottom: '-50px',
          zIndex: '-1'
        }}
      >
        {artifacts}
      </div>
      <div className="container inset">
        <div className="ad-section-layout">
          <RetirementCalculator showTitle {...props} />
          <SalaryCalculator />
          <IdeaSection {...props} />
          <HowSection {...props} />
          <TipSection />
          {/*<Sidebar />*/}
        </div>
      </div>
    </section>
  );
};
