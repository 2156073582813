import React from 'react';
import { artifacts } from '../utils/icons';
import './HowSection.css';

export const HowSection = ({ meta, html }) => {
  return (
    <div className="how-section">
      <div className="how-section__title-row" style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '300px',
            top: '0px',
            zIndex: '1'
          }}
        >
          {artifacts}
        </div>
        <div className="title-row-content">
          <h2 className="fs-36 tc-white">{meta.title}</h2>
          <p className="tc-white">{meta.subtitle}</p>
        </div>
      </div>
      <div
        className="how-section-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};
