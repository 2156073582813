import React from "react";
import Link from "gatsby-link";
import kebabCase from "lodash.kebabcase";
import "./PostListItem.css";

export const PostListItem = ({
  content: {
    childMarkdownRemark: { excerpt }
  },
  date,
  title,
  category
}) => {
  const path = `/${category}/${kebabCase(title)}`;
  return (
    <div className="card post-list-item">
      <Link to={path} className="title">
        <h1>{title}</h1>
      </Link>
      <h4 className="author">
        By <span className="tc-purple">{"Staff"}</span>
      </h4>
      <p className="date tc-grey fs-14">{date}</p>
      <p className="snippet">{excerpt}</p>
      <Link to={path} className="tc-teal read-more">
        Read More &#8594;
      </Link>
    </div>
  );
};
