import React from "react";
import InputRange from "react-input-range";
import { formatPrice } from "../utils";
import imageSrc from "../images/retirement-helpers.svg";
import "./RetirementCalculator.css";

export class RetirementCalculator extends React.Component {
  static defaultProps = {
    showTitle: false
  };
  state = {
    value: 2200,
    value2: 2500,
    value3: 1300
  };
  render() {
    const { value, value2, value3 } = this.state;
    const total = value + value2 + value3;
    const savingTotal = (total * 12) / 0.04;

    return (
      <div className="retirement-calculator animate">
        {this.props.showTitle && (
          <div className="heading-group">
            <h2 className="fs-36 fw-bold">
              How much money do I need for retirement?
            </h2>
            <p>How much should you have saved when you retire…</p>
          </div>
        )}

        <div className="card retirement-card-layout">
          <div className="retirement-input-container">
            <h3 className="tc-blue mb-3">Current Expenses</h3>
            <div className="label-row">
              <span>Necessities (food, house, bills)</span>
              <span className="tc-purple">{`${formatPrice(value)} / mo`}</span>
            </div>
            <InputRange
              step={100}
              maxValue={20000}
              minValue={0}
              value={value}
              onChange={value => this.setState({ value })}
            />

            <div className="label-row">
              <span>
                Discretionaries (travel, entertainment, gifts etc... )
              </span>
              <span className="tc-purple">{`${formatPrice(value2)} / mo`}</span>
            </div>

            <InputRange
              step={100}
              maxValue={20000}
              minValue={0}
              value={this.state.value2}
              onChange={value2 => this.setState({ value2 })}
            />

            <div className="label-row">
              <span>Misc Expenses: (Healthcare & Insurance)</span>
              <span className="tc-purple">{`${formatPrice(value3)} / mo`}</span>
            </div>

            <InputRange
              step={100}
              maxValue={20000}
              minValue={0}
              value={value3}
              onChange={value3 => this.setState({ value3 })}
            />

            <div className="label-row">
              <b>Total:</b>
              <span className="tc-purple">{`${formatPrice(total)} / mo`}</span>
            </div>
          </div>
          <div className="retirement-results-container">
            <div className="retirement-results">
              <p className="goal-text">Your goal for retirement savings</p>
              <p className="savings-total">{formatPrice(savingTotal)}</p>
              <label className="savings-total-label">
                Based on 4% retirement annuity
              </label>
              <img
                className="helpers-img"
                alt="illustration of financial aids"
                src={imageSrc}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
