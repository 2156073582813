import React from 'react';
import Chart from './Chart';
import { ChartRow } from './ChartRow';
import { formatPrice, getLifestyle, getMonthlyIncome } from '../utils';
import { ChartInputHeading } from './ChartInputHeading';
import { ChartInflationRow } from './ChartInflationRow';
import { calendarIcon, accountIcon } from '../utils/icons';
import locale from '../utils/locale';

export default class HeroCard extends React.Component {
  render() {
    const {
      dataBc,
      dataWc,
      minAge,
      maxAge,
      interestRateHigh,
      interestRateLow,
      inflationRate,
      setAgressiveRate,
      setConservativeRate,
      adjustForInflation
    } = this.props;

    const lastBc = dataBc[dataBc.length - 1];
    const lastWc = dataWc[dataWc.length - 1];
    const bestCase = lastBc.value;
    const worstCase = lastWc.value;
    const bcMonthlyIncome = getMonthlyIncome(bestCase);
    const wcMonthlyIncome = getMonthlyIncome(worstCase);
    return (
      <div className="hero-card">
        <div className="hero-card__top-row">
          <h2 className="tc-black form-title">
            <b className="ztext">{locale.columnHeading2}</b>
          </h2>
          <div className="hero-card__title-row align">
            <span className="age align">
              <b className="top-row-icon">{accountIcon}</b>
              {locale.topLabel1}
              <b>{maxAge}</b>
            </span>
            <span className="year align">
              <b className="top-row-icon">{calendarIcon}</b>
              {locale.topLabel2}
              <b>{lastBc.date}</b>
            </span>
          </div>
        </div>
        <div className="chart-row__titles">
          <div key="values" className="chart-row__values">
            <ChartInputHeading
              label={locale.bestCaseLabel}
              placeholder={`${interestRateHigh}%`}
              value={interestRateHigh}
              setValue={setAgressiveRate}
            />
            <ChartInputHeading
              label={locale.worstCaseLabel}
              placeholder={`${interestRateLow}%`}
              color="#B564D2"
              value={interestRateLow}
              setValue={setConservativeRate}
            />
          </div>
        </div>
        <ChartRow
          heading={locale.cardHeading1}
          text={locale.cardDesc1}
          bestCase={formatPrice(bestCase)}
          worstCase={formatPrice(worstCase)}
        />
        <hr />
        <ChartRow
          heading={locale.cardHeading2}
          text={locale.cardDesc2}
          bestCase={`${formatPrice(bcMonthlyIncome)} / mo`}
          worstCase={`${formatPrice(wcMonthlyIncome)} / mo`}
        />
        <hr />
        <ChartRow
          heading={locale.cardHeading3}
          text={locale.cardDesc3}
          bestCase={
            <span
              className="lifestyle"
              children={getLifestyle(bcMonthlyIncome)}
            />
          }
          worstCase={
            <span
              className="lifestyle"
              children={getLifestyle(wcMonthlyIncome)}
            />
          }
        />
        <hr />
        <ChartInflationRow
          inflationRate={inflationRate}
          handleChange={adjustForInflation}
          heading={locale.inflationLabel}
        />
        <Chart
          minAge={minAge}
          maxAge={maxAge}
          dataBc={dataBc}
          dataWc={dataWc}
        />
      </div>
    );
  }
}
