import React from 'react';
import './Toggle.css';

export class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = ({ target: { checked } }) => {
    this.setState({ checked });
    this.props.handleChange(checked);
  };

  render() {
    return (
      <div className="checkbox-container">
        <input
          id="toggle-input-1"
          className="toggle"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleChange}
          onBlur={this.handleChange}
        />
        <label htmlFor="toggle-input-1" className="toggle-btn" />
      </div>
    );
  }
}
