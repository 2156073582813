import {
  carIcon,
  moneyIcon,
  pigIcon,
  bikeIcon,
  bedIcon,
  coffeeIcon
} from './icons';

export default {
  siteTitle: 'Investment Calculator',
  copyright: `© ${new Date().getFullYear()} Investment Calculator`,
  cardHeading1: 'Investment Total:',
  cardHeading2: 'Retirement Income:',
  cardHeading3: 'Lifestyle:',
  cardDesc1:
    'The total amount of money you’ll receive at retirement, earned from your investments',
  cardDesc2: 'How much you can spend per month',
  cardDesc3: 'The type of living you can expect',
  columnTitle1: 'Higher Risk',
  columnTitle2: 'Lower Risk',
  topLabel1: 'Age: ',
  topLabel2: 'Year: ',
  bestCaseLabel: 'Aggressive',
  worstCaseLabel: 'Conservative',
  inflationLabel: 'Adjusted for inflation',
  formHeading1: 'How much do you want to save each month?',
  formHeading2: 'How much do you currently have saved?',
  formHeading3: 'How long do you want to invest?',
  columnHeading1: 'Your Investment',
  columnHeading2: 'Your Outcome',
  recommendedText: '🌟 recommended',
  inputPlaceholder: '$ Amount',
  readMore: 'Read More',
  ideaSectionTitle: 'Save more by…',
  ideaSectionSub:
    'Investing small amounts can increase your retirement savings over time.',
  ideaSectionData: [
    {
      description: 'Give up coffee purchases (1 year)', // 1200 / yr
      value: 90,
      duration: 1,
      icon: coffeeIcon,
      color: '#E3D4A2',
      url:
        'http://business.time.com/2012/01/23/how-much-you-spend-each-year-on-coffee-gas-christmas-pets-beer-and-more/',
      content: [
        'The average American spends approximately $1,100 per year, or $3 each day, on coffee.',
        'If coffee is too hard to give up, try soft drinks ($850 per year).'
      ]
    },
    {
      description: 'Moonlight as an Uber or Lyft driver (1 year)', // $155/ mo - 1yr
      value: 155,
      duration: 1,
      icon: carIcon,
      color: '#E2B9F0',
      url:
        'https://priceonomics.com/how-much-are-people-making-from-the-sharing/',
      content: [
        'Lyft drivers make $377 per month while Uber drivers make $364 per month.',
        'Drive your car, drive those numbers.'
      ]
    },
    {
      description: 'Rent out an extra room on Airbnb (3 years)', // $450/mo -
      value: 450,
      duration: 3,
      icon: bedIcon,
      color: '#B9F0D8',
      url:
        'https://priceonomics.com/how-much-are-people-making-from-the-sharing/',
      content: [
        'Hosts on Airbnb make an average of $924 per month. ',
        'Rent out an extra room and double your retirement savings.'
      ]
    },
    {
      description: 'Invest your annual tax refund',
      value: 250,
      duration: -1,
      icon: moneyIcon,
      color: '#FBA2D6',
      url:
        'https://www.cnbc.com/2017/04/17/heres-the-average-tax-refund-people-get-in-every-us-state.html',
      content: [
        'The average tax refund in the US is $3,050 per year. ',
        'Avoid spending tax refunds to get to your retirement faster.'
      ]
    },
    {
      description: 'Maximize employer 401k matching', // $2500 / yr
      value: 200,
      duration: -1,
      icon: pigIcon,
      color: '#B9EBF0',
      url: 'https://www.upcounsel.com/average-401k-match',
      content: [
        'The average salary is $44,148 and the average employer matches a 401 by 2.9%.',
        'If your employer offers a 401k match, invest everything you can to max it out.'
      ]
    },
    {
      description: 'Cut your driving mileage in half',
      value: 50,
      duration: -1,
      icon: bikeIcon,
      color: '#E2B9F0',
      url: 'https://www.nerdwallet.com/blog/loans/total-cost-owning-car/',
      content: [
        'Downsizing your driving by 50% could save you around $4,000 per year.',
        'Ditch your car completely and save an average of $8,469 per year.'
      ]
    }
  ],
  tipSectionTitle: 'Investment Resources',
  tipSectionSub: 'Get some of the best advice on how to invest your money',
  articles: [
    {
      description: 'Why You May Need LESS Retirement Income Than You Think',
      url:
        'https://www.marketwatch.com/story/you-may-need-less-retirement-income-than-you-think-2015-11-30',
      color: '#E3D4A2'
    },
    {
      description: 'IRA: What It Is and How to Get Started',
      url: 'https://investor.vanguard.com/ira/iras',
      color: '#E2B9F0'
    },
    {
      description: 'Financial Advisors: What You Need to Know to Choose One',
      url:
        'http://guides.wsj.com/personal-finance/managing-your-money/how-to-choose-a-financial-planner/',
      color: '#B9F0D8'
    },
    {
      description: 'The 5 Best Investment Newsletters For Stock Market Advice',
      url:
        'https://www.yoreoyster.com/blog/best-investment-newsletters/',
      color: '#FBA2D6'
    },
    {
      description: 'Using Your HSA for Retirement',
      url:
        'https://www.investopedia.com/articles/personal-finance/091615/how-use-your-hsa-retirement.asp',
      color: '#B9EBF0'
    },
    {
      description: 'Considering a 401(k) Loan? Know These Hidden Dangers',
      url:
        'https://www.investopedia.com/articles/retirement/06/eightreasons401k.asp',
      color: '#E2B9F0'
    },
    {
      description: 'How Much to Have Saved in Your IRA by Age',
      url:
        'https://www.usatoday.com/story/money/personalfinance/retirement/2018/01/01/retirement-planning-heres-how-much-money-you-should-have-saved-every-age/990172001/',
      color: '#E3D4A2'
    },
    {
      description:
        '5 Advantages of a Self-Directed Solo 401(k) Plan for the Self-Employed',
      url:
        'https://investorjunkie.com/56359/5-advantages-self-directed-solo-401k-plan-self-employed/',
      color: '#E2B9F0'
    },
    {
      description: 'SEP IRA vs. Solo 401(k) – Which Is Better for You?',
      url: 'https://investorjunkie.com/13066/sep-ira-solo-401k/',
      color: '#B9F0D8'
    }
  ],
  footerHeading: 'Get the latest in your inbox',
  footerLabel: 'We promise not to spam you',
  footerPlaceholder: 'Enter your email',
  footerSub: 'Do you like the investment calculator?',
  footerDescription:
    'Subcribe to be the first to know when we publish updates and get the latest investment tips.'
};
