import { anuityRate } from "./constants";

export const formatPrice = num => {
  return num
    .toLocaleString("en-US", { style: "currency", currency: "USD" })
    .split(".")[0];
};

export const concatPrice = num => {
  return num >= 1000
    ? num
        .toLocaleString("en-US", { style: "currency", currency: "USD" })
        .split(",")[0]
        .concat(num < 1000000 ? "K" : "M")
    : formatPrice(num);
};

export const toInterestRate = num => num / 100;

export const toGrowthRate = num => num / 100 + 1;

export const getData = (
  years = 10,
  rate = 8,
  ammt = 250,
  principal = 1000,
  inflationRate
) => {
  const year = new Date().getFullYear();

  let data = [{ date: year, value: principal }];
  const adjustedRate = clamp(
    inflationRate ? rate - inflationRate : rate,
    1,
    40
  );

  Array.from({ length: years })
    .fill(ammt)
    .reduce((acc, curr, i) => {
      const value = Math.round((acc + ammt * 12) * toGrowthRate(adjustedRate));
      data.push({
        date: year + i,
        value
      });
      return value;
    }, principal);

  return data;
};

export const getTotal = (
  years = 0,
  bonus = 0,
  bonusDuration = 0,
  rate = 8,
  ammt = 0,
  principal = 0,
  inflationRate
) => {
  return Array.from({ length: years })
    .fill(ammt)
    .reduce(
      (acc, curr, i) => {
        const bd = bonusDuration < 0 ? years : bonusDuration;
        const adjustedRate = clamp(
          inflationRate ? rate - inflationRate : rate,
          1,
          40
        );
        return bd >= i
          ? Math.round((acc + (ammt + bonus) * 12) * toGrowthRate(adjustedRate))
          : Math.round((acc + ammt * 12) * toGrowthRate(adjustedRate));
      },

      principal
    );
};

export const recommendedBalance = num =>
  num < 30 ? 10000 : num < 40 ? 40000 : 100000;

export const recommendedSavings = num =>
  num < 30 ? 100 : num < 40 ? 500 : 1000;

export const getLifestyle = num =>
  num >= 10000 ? "Comfortable" : num >= 5000 ? "Content" : "Frugal";

export const getMonthlyIncome = num => (num * toInterestRate(anuityRate)) / 12;

export const random = (min, max) => Math.random() * (max - min) + min;

export const parseFloat = val => Math.abs(!!parseInt(val) ? parseInt(val) : 1);

export const clamp = (n, min, max) => {
  return Math.max(Math.min(n, max), min);
};
